export enum V1MagicSchools {
    Abjuration = "Abjuration",
    Alteration = "Alteration",
    Conjuration = "Conjuration",
    Divination = "Divination",
    Enchantment = "Enchantment",
    Illusion = "Illusion",
    Evocation = "Evocation",
    Necromancy = "Necromancy",
    Transmutation = "Transmutation"
}