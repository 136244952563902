import { Card, Elevation } from "@blueprintjs/core";
import { GetLang, SpellText } from "../Helper/LanguageHelper";
import { V1Spell } from "../Models/Spells/V1Spell";
import { V1Languages } from "../Models/Spells/v1Languages";
import { useEffect, useState } from "react";
import SpellService from "../Api/SpellService";

export interface ISpellCard {
  Id:String;
  Language: V1Languages;
}

export const SpellCard: React.FC<ISpellCard> = (props) => {

  const [Spell, setSpell] = useState<V1Spell | null>(null);

  useEffect(() => {
    retrieveSpell();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const retrieveSpell = () => {
    SpellService.getSpell(props.Id)
      .then((response: any) => {
        setSpell(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  if (Spell === null){
    return <div>Loading...</div>
  }

  return (
    <Card key={Spell.Id} interactive={true} elevation={Elevation.TWO}>
      <h3>{GetLang(Spell.Name, props.Language)}</h3>
      <div>
        <i>{GetLang(Spell.Type, props.Language)}</i>
      </div>
      <div>
        <strong>{GetLang(SpellText.CastingTime, props.Language)}: </strong>
        {GetLang(Spell.CastingTime, props.Language)}
      </div>
      <div>
        <strong>{GetLang(SpellText.Range, props.Language)}: </strong>
        {GetLang(Spell.Range, props.Language)}
      </div>
      <div>
        <strong>{GetLang(SpellText.Components, props.Language)}: </strong>
        {GetLang(Spell.Components.Raw, props.Language)}
      </div>
      <div>
        <strong>{GetLang(SpellText.Duration, props.Language)}: </strong>
        {GetLang(Spell.Duration, props.Language)}
      </div>

      <div>{GetLang(Spell.Description, props.Language)}</div>
      {Spell.HigherLevels && (
        <div>
          <strong>{GetLang(SpellText.AtHigherLevels, props.Language)}: </strong>
          {GetLang(Spell.HigherLevels, props.Language)}
        </div>
      )}
      <div>
        <strong>{GetLang(SpellText.Classes, props.Language)}: </strong>
        {Spell.Classes.join(",")}
      </div>
    </Card>
  );
};
export default SpellCard;
