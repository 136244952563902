import React, { useEffect, useState } from "react";
import { V1Spell } from "../Models/Spells/V1Spell";
import SpellService from "../Api/SpellService";
import { V1Languages } from "../Models/Spells/v1Languages";
import SpellCard, { ISpellCard } from "../Components/SpellCard";
import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Switch,
} from "@blueprintjs/core";
import { V1Classes } from "../Models/Spells/v1Classes";
import { V1SpellFilter } from "../Models/Spells/V1SpellFilter";
import { V1MagicSchools } from "../Models/Spells/v1MagicSchools";
import SpellTabs, { ISpellTabs } from "../Components/SpellTabs";

const SpellsList: React.FC = () => {
  const [Spells, setSpells] = useState<Array<V1Spell>>([]);
  const [currentSpell, setCurrentSpell] = useState<string | null>(null);
  const [selectedLang, setSelectedLang] = useState<V1Languages>(V1Languages.DE);
  const [selectedClass, setSelectedClass] = useState<V1Classes | null>(null);
  const [selectedSchool, setSelectedSchool] = useState<V1Classes | null>(null);
  
  const [allowVerbal, setAllowVerbal] = useState<boolean>(true);
  const [allowSomatic, setAllowSomatic] = useState<boolean>(true);
  const [allowMaterial, setAllowMaterial] = useState<boolean>(true);
  const [mustBeRitual, setMustBeRitual] = useState<boolean>(false);
  const [mustCastHigherLevel, setMustCastHigherLevel] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");

  const getSpellFilter = () => {
    return {
      Class: selectedClass,
      School: selectedSchool,
      AllowVerbal: allowVerbal,
      AllowSomatic: allowSomatic,
      AllowMaterial: allowMaterial,
      MustBeRitual: mustBeRitual,
      MustCastHigherLevel:mustCastHigherLevel,
      Search:search
    } as V1SpellFilter;
  };

  useEffect(() => {
    retrieveSpells();
    setCurrentSpell(null);
  }, [selectedClass, selectedSchool,allowVerbal,allowSomatic,allowMaterial,mustBeRitual,mustCastHigherLevel]);// eslint-disable-line react-hooks/exhaustive-deps

  const updateSpells = () => {
    retrieveSpells();
    setCurrentSpell(null);
  }

  const retrieveSpells = () => {
    SpellService.getAllSpells(getSpellFilter())
      .then((response: any) => {
        setSpells(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <div className="spelllist_container">
      <div className="menue">
        <div className="languageselector">
          <Button
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              setSelectedLang(V1Languages.DE);
            }}
          >
            DE
          </Button>
          <Button
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              setSelectedLang(V1Languages.EN);
            }}
          >
            EN
          </Button>
        </div>
        <ControlGroup className="filter-search" fill={true} vertical={false} >
          <InputGroup
              type="search"
              placeholder="Search"
              fill={false}
              value={search}
              onChange={(e) => {setSearch(e.target.value)}}
            ></InputGroup>
            <Button className="searchbutton" icon="search"
              fill={false}
              onClick={(e: React.MouseEvent<HTMLElement>): void => {
              updateSpells();
            }}
            ></Button>
        </ControlGroup>
          
          <HTMLSelect
            className="filter-class"
            onChange={(e: any): void => {
              setSelectedClass(
                e.currentTarget.value === "null" ? null : e.currentTarget.value
              );
            }}
            defaultValue={"null"}
          >
            <option value={"null"}>
              All Classes
            </option>
            <option value={V1Classes.Bard}>Bard</option>
            <option value={V1Classes.Cleric}>Cleric</option>
            <option value={V1Classes.Druid}>Druid</option>
            <option value={V1Classes.Paladin}>Paladin</option>
            <option value={V1Classes.Ranger}>Ranger</option>
            <option value={V1Classes.Sorcerer}>Sorcerer</option>
            <option value={V1Classes.Warlock}>Warlock</option>
            <option value={V1Classes.Wizard}>Wizard</option>
          </HTMLSelect>
          <HTMLSelect
            className="filter-school"
            onChange={(e: any): void => {
              setSelectedSchool(
                e.currentTarget.value === "null" ? null : e.currentTarget.value
              );
            }}
            defaultValue={"null"}
          >
            <option value={"null"}>
              All Schools
            </option>
            <option value={V1MagicSchools.Abjuration}>Abjuration</option>
            <option value={V1MagicSchools.Alteration}>Alteration</option>
            <option value={V1MagicSchools.Conjuration}>Conjuration</option>
            <option value={V1MagicSchools.Divination}>Divination</option>
            <option value={V1MagicSchools.Enchantment}>Enchantment</option>
            <option value={V1MagicSchools.Evocation}>Evocation</option>
            <option value={V1MagicSchools.Illusion}>Illusion</option>
            <option value={V1MagicSchools.Necromancy}>Necromancy</option>
            <option value={V1MagicSchools.Transmutation}>Transmutation</option>
          </HTMLSelect>
          <FormGroup
            className="filter-components"
            label="Allowed Spell Components: "
            inline={true}
          >
            <Switch
              inline={true}
              checked={allowVerbal}
              label="Verbal"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAllowVerbal(e.target.checked);
              }}
            />
            <Switch
              inline={true}
              checked={allowSomatic}
              label="Somatic"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAllowSomatic(e.target.checked);
              }}
            />
            <Switch
              inline={true}
              checked={allowMaterial}
              label="Material"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAllowMaterial(e.target.checked);
              }}
            />
          </FormGroup>
          <Switch
            inline={true}
            className="filter-ritual"
            checked={mustBeRitual}
            label="Only Rituals"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMustBeRitual(e.target.checked);
            }}
          />
          <Switch
            inline={true}
            className="filter-higherlevel"
            checked={mustCastHigherLevel}
            label="Only with 'At higher Levels'"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMustCastHigherLevel(e.target.checked);
            }}
          />
      </div>
      <div className="currentSpell">
        {currentSpell && (
          <SpellCard
            key={currentSpell}
            {...({ Language: selectedLang, Id: currentSpell } as ISpellCard)}
          />
        )}
      </div>
      <div className="spellTabs">
        <SpellTabs
          key={"SpellTabs"}
          {...({
            Language: selectedLang,
            Spells: Spells,
            setCurrentSpell: setCurrentSpell,
          } as ISpellTabs)}
        />
      </div>
    </div>
  );
};

export default SpellsList;
