import { V1LangString } from "../Models/Spells/v1LangString";
import { V1Languages } from "../Models/Spells/v1Languages";
import parse from 'html-react-parser';

export const GetLang = (LS: V1LangString[], Language: V1Languages) => {
    if (LS === null){
        return parse("");
    }
    var selected = LS.find((e:V1LangString) => e.Language === Language);
    if (selected?.Value === null){
        selected = LS.find((e:V1LangString) => e.Language === V1Languages.EN);
    }
    //console.log(selected);
    //debugHtml(LS);  
    return (
        parse(selected?.Value??"")
    );
  };

// function debugHtml(LS: V1LangString[]) {
//     var ls = LS.find((e: V1LangString) => e.Language === V1Languages.DE)?.Value ?? "";
//     if (ls !== "") {
//         var parser = new DOMParser();
//         let doc = parser.parseFromString("<div>" + ls + "</div>", "application/xml");
//         let errorNode = doc.querySelector('parsererror');
//         if (errorNode) {
//             console.log(errorNode);
//             console.log(ls);
//         } else {
//         }
//     }
// }

export const SpellText = {
    CastingTime:[
        {
            Language:V1Languages.EN,
            Value: "Casting Time"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Zeitaufwand"
        } as V1LangString,
    ],
    Level:[
        {
            Language:V1Languages.EN,
            Value: "Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Grad"
        } as V1LangString,
    ],
    School:[
        {
            Language:V1Languages.EN,
            Value: "School"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Schule"
        } as V1LangString,
    ],
    Range:[
        {
            Language:V1Languages.EN,
            Value: "Range"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Reichweite"
        } as V1LangString,
    ],
    Components:[
        {
            Language:V1Languages.EN,
            Value: "Components"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Komponenten"
        } as V1LangString,
    ],
    Duration:[
        {
            Language:V1Languages.EN,
            Value: "Duration"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Wirkungsdauer"
        } as V1LangString,
    ],
    Ritual:[
        {
            Language:V1Languages.EN,
            Value: "Ritual"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Ritual"
        } as V1LangString,
    ],
    Classes:[
        {
            Language:V1Languages.EN,
            Value: "Classes"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Klassen"
        } as V1LangString,
    ],
    AtHigherLevels:[
        {
            Language:V1Languages.EN,
            Value: "At Higher Levels"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Auf höheren Graden"
        } as V1LangString,
    ],
    Cantrip:[
        {
            Language:V1Languages.EN,
            Value: "Cantrip"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "Zaubertrick"
        } as V1LangString,
    ],
    SpellLv1:[
        {
            Language:V1Languages.EN,
            Value: "1st Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "1. Grad"
        } as V1LangString,
    ],
    SpellLv2:[
        {
            Language:V1Languages.EN,
            Value: "2nd Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "2. Grad"
        } as V1LangString,
    ],
    SpellLv3:[
        {
            Language:V1Languages.EN,
            Value: "3rd Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "3. Grad"
        } as V1LangString,
    ],
    SpellLv4:[
        {
            Language:V1Languages.EN,
            Value: "4th Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "4. Grad"
        } as V1LangString,
    ],
    SpellLv5:[
        {
            Language:V1Languages.EN,
            Value: "5th Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "5. Grad"
        } as V1LangString,
    ],
    SpellLv6:[
        {
            Language:V1Languages.EN,
            Value: "6th Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "6. Grad"
        } as V1LangString,
    ],
    SpellLv7:[
        {
            Language:V1Languages.EN,
            Value: "7th Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "7. Grad"
        } as V1LangString,
    ],
    SpellLv8:[
        {
            Language:V1Languages.EN,
            Value: "8th Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "8. Grad"
        } as V1LangString,
    ],
    SpellLv9:[
        {
            Language:V1Languages.EN,
            Value: "9th Level"
        } as V1LangString,
        {
            Language:V1Languages.DE,
            Value: "9. Grad"
        } as V1LangString,
    ],
}
