import { Button, Tab, TabId, Tabs } from "@blueprintjs/core";
import { V1Spell } from "../Models/Spells/V1Spell";
import { V1Languages } from "../Models/Spells/v1Languages";
import { GetLang, SpellText } from "../Helper/LanguageHelper";
import { useState } from "react";

export interface ISpellTabs {
  Spells: Array<V1Spell>;
  Language: V1Languages;
  setCurrentSpell: Function;
}

export const SpellTabs: React.FC<ISpellTabs> = (props) => {
  const [currentTab, setCurrentTab] = useState<number | string | null>(null);

  const SpellSortCompare = (a:V1Spell,b:V1Spell)=> {return GetLang(a.Name, props.Language).toString().localeCompare(GetLang(b.Name, props.Language).toString())}

  //Sort into Level Lists
  const Cantrips = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 0;
  }).sort(SpellSortCompare);
  const Lv1Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 1;
  }).sort(SpellSortCompare);
  const Lv2Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 2;
  }).sort(SpellSortCompare);
  const Lv3Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 3;
  }).sort(SpellSortCompare);
  const Lv4Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 4;
  }).sort(SpellSortCompare);
  const Lv5Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 5;
  }).sort(SpellSortCompare);
  const Lv6Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 6;
  }).sort(SpellSortCompare);
  const Lv7Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 7;
  }).sort(SpellSortCompare);
  const Lv8Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 8;
  }).sort(SpellSortCompare);
  const Lv9Spells = props.Spells?.filter((s: V1Spell) => {
    return s.Level === 9;
  }).sort(SpellSortCompare);
  // Check if Tab still exists
  if (currentTab === 0 && Cantrips.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 1 && Lv1Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 2 && Lv2Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 3 && Lv3Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 4 && Lv4Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 5 && Lv5Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 6 && Lv6Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 7 && Lv7Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 8 && Lv8Spells.length === 0) {
    setCurrentTab(null);
  }
  if (currentTab === 9 && Lv9Spells.length === 0) {
    setCurrentTab(null);
  }
  //Find first Tab for Default
  let firstTab = -1;
  let foundTab = false;
  if (Cantrips.length > 0) {
    firstTab = 0;
    foundTab = true;
  }
  if (!foundTab && Lv1Spells.length > 0) {
    firstTab = 1;
    foundTab = true;
  }
  if (!foundTab && Lv2Spells.length > 0) {
    firstTab = 2;
    foundTab = true;
  }
  if (!foundTab && Lv3Spells.length > 0) {
    firstTab = 3;
    foundTab = true;
  }
  if (!foundTab && Lv4Spells.length > 0) {
    firstTab = 4;
    foundTab = true;
  }
  if (!foundTab && Lv5Spells.length > 0) {
    firstTab = 5;
    foundTab = true;
  }
  if (!foundTab && Lv6Spells.length > 0) {
    firstTab = 6;
    foundTab = true;
  }
  if (!foundTab && Lv7Spells.length > 0) {
    firstTab = 7;
    foundTab = true;
  }
  if (!foundTab && Lv8Spells.length > 0) {
    firstTab = 8;
    foundTab = true;
  }
  if (!foundTab && Lv9Spells.length > 0) {
    firstTab = 9;
    foundTab = true;
  }

  return (
    <>
      {foundTab && (
        <Tabs
          id="SpellTabs"
          vertical={true}
          onChange={(
            newTabId: TabId,
            prevTabId: TabId | undefined,
            event: React.MouseEvent<HTMLElement>
          ) => {
            setCurrentTab(newTabId);
          }}
          selectedTabId={currentTab === null ? firstTab : currentTab}
        >
          {Cantrips.length > 0 && (
            <Tab
              id={0}
              title={GetLang(SpellText.Cantrip, props.Language)}
              panel={
                <ul>
                  {Cantrips.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv1Spells.length > 0 && (
            <Tab
              id={1}
              title={GetLang(SpellText.SpellLv1, props.Language)}
              panel={
                <ul>
                  {Lv1Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv2Spells.length > 0 && (
            <Tab
              id={2}
              title={GetLang(SpellText.SpellLv2, props.Language)}
              panel={
                <ul>
                  {Lv2Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv3Spells.length > 0 && (
            <Tab
              id={3}
              title={GetLang(SpellText.SpellLv3, props.Language)}
              panel={
                <ul>
                  {Lv3Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv4Spells.length > 0 && (
            <Tab
              id={4}
              title={GetLang(SpellText.SpellLv4, props.Language)}
              panel={
                <ul>
                  {Lv4Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv5Spells.length > 0 && (
            <Tab
              id={5}
              title={GetLang(SpellText.SpellLv5, props.Language)}
              panel={
                <ul>
                  {Lv5Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv6Spells.length > 0 && (
            <Tab
              id={6}
              title={GetLang(SpellText.SpellLv6, props.Language)}
              panel={
                <ul>
                  {Lv6Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv7Spells.length > 0 && (
            <Tab
              id={7}
              title={GetLang(SpellText.SpellLv7, props.Language)}
              panel={
                <ul>
                  {Lv7Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv8Spells.length > 0 && (
            <Tab
              id={8}
              title={GetLang(SpellText.SpellLv8, props.Language)}
              panel={
                <ul>
                  {Lv8Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
          {Lv9Spells.length > 0 && (
            <Tab
              id={9}
              title={GetLang(SpellText.SpellLv9, props.Language)}
              panel={
                <ul>
                  {Lv9Spells.map((Spell, index) => (
                    <li key={Spell.Id}>
                      <Button
                        fill={true}
                        onClick={(e: React.MouseEvent<HTMLElement>): void => {
                          props.setCurrentSpell(Spell.Id);
                        }}
                      >
                        {GetLang(Spell.Name, props.Language)}
                      </Button>
                    </li>
                  ))}
                </ul>
              }
            />
          )}
        </Tabs>
      )}
      {!foundTab && <div>No Spells Found</div>}
    </>
  );
};
export default SpellTabs;


