import { V1Spell } from "../Models/Spells/V1Spell";
import { V1SpellFilter } from "../Models/Spells/V1SpellFilter";
import { V1SpellShort } from "../Models/Spells/V1SpellShort";
import http from "./http-common";

const getAllSpells = (filter:V1SpellFilter) => {
    return http.post<Array<V1SpellShort>>(
      "/GetSpells",
      filter
      );
};


const getSpell = (Id:String) => {
  return http.post<V1Spell>(
    "/GetSpell",
    {Id:Id}
    )
}

const SpellService = {
  getAllSpells,
  getSpell
};

export default SpellService;