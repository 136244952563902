import React, { Component } from "react";
import {
    Route,
    HashRouter,
    Routes,
    useParams
 } from "react-router-dom";
import Spells from "./pages/SpellsList";
import Home from "./pages/Home";
import SpellCard, { ISpellCard } from "./Components/SpellCard";
import { V1Languages } from "./Models/Spells/v1Languages";
 
class Main extends Component {
  render() {
    return (
        <HashRouter>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <Routes>
              <Route path="/home" element={<Home/>}/>
              <Route path="/" element={<Spells/>}/>
              <Route path="/spells" element={<Spells/>}/>
              <Route path="/spell/:Id" element={<SpellCardContainer />}/>
            </Routes>
        </HashRouter>
    );
  }
}
 
export default Main;

function SpellCardContainer() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { Id } = useParams();

  return (
      <SpellCard key={Id} {...{Language:V1Languages.DE,Id:Id} as ISpellCard} />
  );
}